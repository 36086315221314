/**
 * Created by osirvent on 18/11/2015.
 */
angular
    .module('annexaApp')
    .constant('BooksModals',{
    	bookNew: {
             title: 'global.books.list.new',
             size: 'modal-lg',
             wizard: {
                 modal: undefined,
                 finishWizard: function () {
                     if(this.modal) {
                         this.modal.submitModal();
                     }
                 },
                 exitValidation: function (form) {
                     return form && !form.$invalid;
                 },
                 steps: [
                     {
                         title: '',
                         key: 'book',
                         disabled: false,
                         disableNext: function (model) {
                        	 var disabled = true;
                        	 if(model && model.bookType && model.name){
                        		 disabled = false;
                        	 }
                             return disabled;
                         },
                         exitValidation: function (model,step,wizard) {
                        	 var exit = false;
                        	 if(model && model.bookType && model.name){
                        		exit = true;
                        	 }
                        	 return exit;
                         },
                         beforeNext: function (step, wizard) {
                        	 if(wizard.listOfBookTypes && step.annexaFormly.model.bookType){
                      			var bookType = $linq(wizard.listOfBookTypes).firstOrDefault(undefined, "x => x.id == "+step.annexaFormly.model.bookType);
                      			if(bookType && bookType.indexTemplate){
                      				var archiveClassificationAux = undefined;
                      				if(bookType.indexTemplate.archiveClassification && bookType.indexTemplate.archiveClassification.id){
                      					archiveClassificationAux = {model:{$selected:{id: bookType.indexTemplate.archiveClassification.id, title: bookType.indexTemplate.archiveClassification[wizard.languageColumn]}}}
                      				}
                      				
                      				var newDocumentModal = wizard.documentService.getOpenNewDocumentModal(undefined, undefined, wizard.module, undefined, undefined, archiveClassificationAux, ((bookType.indexTemplate.documentType && bookType.indexTemplate.documentType.id)?wizard.getDocumentType(bookType.indexTemplate.documentType.id):undefined));
		                     		if(newDocumentModal &&  newDocumentModal.annexaFormly && newDocumentModal.annexaFormly.model){
		                     			wizard.steps[1].annexaFormly.model = newDocumentModal.annexaFormly.model;
		                     			wizard.steps[1].annexaFormly.model.modal_body.inputType = 'template';
	             						wizard.steps[1].annexaFormly.model.modal_body.templateDefaultBooks = {$selected:{
	             							id: bookType.indexTemplate.id, 
	             							parent:false, 
	             							selectable:false, 
	             							size:0, 
	             							title:bookType.indexTemplate.name
	             						}};
	             						wizard.steps[1].annexaFormly.model.modal_body.oneProfile = false;
	             						wizard.steps[1].annexaFormly.model.modal_body.profiles = $linq(bookType.bookTypeprocessProfiles).where("x => x.profile && x.profile.id").select("x => x.profile.id").toArray();
		                     		}else{
		                     			wizard.steps[1].annexaFormly.model = {};
		                     		}
		                     		wizard.steps[1].annexaFormly.fields.length = 0;
		                     		if(newDocumentModal &&  newDocumentModal.annexaFormly && newDocumentModal.annexaFormly.fields){
			                     		_.forEach(newDocumentModal.annexaFormly.fields, function(field){
			                     			if(field && field.fieldGroup){
			                     				_.forEach(field.fieldGroup, function(fieldGroup){
			                     					if(fieldGroup && fieldGroup.key == 'typeSelect' && fieldGroup.templateOptions){
			                     						fieldGroup.templateOptions.disabled = true;
			                     					}else if(fieldGroup && fieldGroup.key == 'template' && fieldGroup.templateOptions){
			                     						fieldGroup.templateOptions.disabled = true;
			                     					}else if(fieldGroup && fieldGroup.key == 'title'){
			                     						fieldGroup.controller = ['$scope', 'DccumentsFactory', 'Language', '$rootScope', 'GlobalDataFactory', function($scope, DccumentsFactory, Language, $rootScope, GlobalDataFactory) {
			                                                $scope.model.title = wizard.steps[0].annexaFormly.model.name;
			                                            }];
			                     					}
			                     				});		
			                     			}
			                     			wizard.steps[1].annexaFormly.fields.push(field);
			                     		});
		                     		}
                     				
                     			}
                     		}
                         },
                         annexaFormly: {
                             fields: [
                                 {
                                     key: 'bookType',
                                     type: 'annexaSelectRow',
                                     className: 'col-sm-12',
                                     templateOptions: {
                                         optionsAttr: 'bs-options',
                                         ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                         label: 'global.books.list.bookType',
                                         valueProp: 'id',
                                         labelProp: 'language1',
                                         placeholder: '',
                                         options: [],
                                         required: true,
                                         onSelected: function(item, options) {
                                             var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                             $rootScope.$broadcast('newBookTypeSelected', { item: item });
                                         },
                                         focus: true
                                     },
                                     controller: ['$scope', 'BooksFactory', 'Language', function ($scope, BooksFactory, Language) {
                                         $scope.options.templateOptions.labelProp = Language.getActiveColumn();
                                         $scope.options.templateOptions.options = BooksFactory.bookTypesToUpdate;
                                     }],
                                     data: {
                                         row: true,
                                         informed: true,
                                         colClass: ' col-sm-12',
                                         labelClass: 'label-strong'
                                     }
                                 },
                            	 {
                                     key: 'name',
                                     type: 'annexaInputRow',
                                     className: 'col-sm-12',
                                     templateOptions: {
                                         type: 'text',
                                         label: 'global.literals.name',
                                         required: true,
                                         focus: false,
                                         maxlength: 500
                                     },
                                     data: {
                                         row: true,
                                         colClass: ' col-sm-12',
                                         labelClass: 'label-strong'
                                     },
                                     controller:['$scope','BooksFactory','Language', function($scope, BooksFactory, Language){
                                    	 $scope.$on('newBookTypeSelected', function(event, args) {
                                    		 var selectedType = $linq(BooksFactory.bookTypesToUpdate).singleOrDefault(undefined, "x => x.id == " + $scope.model.bookType);
                                    		 if(selectedType){
                                    			 $scope.model.name = selectedType[Language.getActiveColumn()];
                                    		 }
                                         });

                                     }]
                                 }
                             ]
                         }
                     },
                     {
                         title: '',
                         key: 'document',
                         disabled: false,
                         disableNext: function (model) {
                             return false;
                         },
                         exitValidation: function (model) {
                             return true;
                         },
                         annexaFormly: {
                             fields: []
                         }
                     }
                 ]
             },
             submitModal: function () {
             },
             alerts: []
         }
    })
;